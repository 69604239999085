.Topbar {
  width: calc(100vw - #{$sidebar-max-width});
  margin-left: -15px;
  position: fixed;
  z-index: 2;

  @media (max-width: 1224px) {
    width: calc(100vw - #{$sidebar-width});
  }

  @include media-breakpoint-down(md) {
    .navbar-expand-lg .navbar-toggler {
      display: block;
    }
  }

  @include media-breakpoint-down(md) {
    width: 100vw;
  }

  .navbar {
    padding: 0;
    margin: 0;

    .navbar-toggler {
      border: 0;
      padding: 0 10px;
      margin-top: 10px;
      span {
        background: none;
        color: $blue;
        font-size: $font-size + 0.5;
      }
    }
  }

  .Sezione &,
  .Risultati & {
    width: calc(100vw - #{$sidebar-max-width} - #{$submenu-max-width});
    margin-left: -15px;
    transition: all 0.25s ease-out;

    @media (max-width: 1224px) {
      width: calc(100vw - #{$sidebar-width} - #{$submenu-width});
    }

    @include media-breakpoint-down(md) {
      width: 100vw;
    }

    &.no-submenu {
      width: calc(100vw - #{$sidebar-max-width});

      @media (max-width: 1224px) {
        width: calc(100vw - #{$sidebar-width});
      }

      @include media-breakpoint-down(md) {
        width: 100vw;
      }
    }
  }

  .abbonamenti {
    background: $orange;
    color: $white !important;
    text-transform: uppercase;
    margin-right: 20px;
    font-size: $font-size - 0.3;
    padding: 8px 16px;
    margin-top: -2px;

    &:hover {
      background: darken($orange, 15%);
    }
  }

  .topbar-login {
    display: inline-block;
    background: $blue;
    padding: 8px 50px 8px 20px;
    color: $white;
    cursor: pointer;

    span {
      display: inline-block;
    }

    .circle {
      font-size: 0.6rem;
      background: $white;
      width: 19px;
      height: 19px;
      border-radius: 100%;
      color: $blue;
      text-align: center;
      line-height: 21px;
      margin-right: 13px;
    }

    .name {
      font-size: $font-size - 0.15;
    }

    @include media-breakpoint-down(md) {
      padding: 11px 20px;

      .circle {
        margin-right: 0;
        font-size: 0.8rem;
        width: 25px;
        height: 25px;
        line-height: 26px;
      }

      .name {
        display: none;
      }
    }
  }

  .topbar-login-dropdown {
    visibility: hidden;
    width: 300px;
    padding: 0 15px;
    background: $blue;
    position: absolute;
    right: 0;
    transition: all 0.2s ease-in-out;

    &.open {
      visibility: visible;
      padding: 30px 15px;
    }

    .btn {
      width: calc(100% - 15px);
      background: $white;
      border-color: $white;
      border-radius: 0;
      color: $blue;
      text-transform: uppercase;

      &:hover {
        background: $orange;
        border-color: $orange;
        color: $white;
      }
    }
  }
}
