.Submenu .ScrollbarsCustom {
  width: 100%;
  min-height: 90vh;
  padding: 30px 0;

  @include media-breakpoint-up(lg) {
    width: calc(#{$submenu-max-width} - 35px) !important;
  }
  @include media-breakpoint-down(sm) {
    // meno padding top e bottom del Submenu
    min-height: calc(90vh - 60px - 30px);
  }
  .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
    width: 6px !important;
    right: -18px !important;

    @include media-breakpoint-down(sm) {
      right: -9px !important;
    }
  }
}


.Sidebar .ScrollbarsCustom {
  width: 100%;
  max-width: $sidebar-max-width;
  min-height: 71vh;
  margin-top: 5vh;

  .ScrollbarsCustom-Wrapper {
    inset: 0px 0px 0px 0px;
  }

  .ScrollbarsCustomScroller {
    padding-right: 0 !important;
  }

  .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
    width: 6px !important;
    right: 6px !important;

    @include media-breakpoint-down(sm) {
      right: -9px !important;
    }
  }

  @include media-breakpoint-down(lg) {
    max-width: unset;
  }
}