.App {
  .white-box {
    background: $white;
    box-shadow: 0px 1px 2px $grey;
  }

  .PageNotFound {
    .btn {
      border: 0;
      border-radius: 0;
    }
  }
}
