.cerca-risultati {
  margin-top: 15px;

  .text-search {
    * {
      display: inline-block;
    }

    .square {
      width: 40px;
      height: 40px;
      background: $light-grey;
      text-align: center;
      line-height: 42px;
      margin: 10px 20px 10px 10px;
    }

    input,
    input.form-control {
      width: calc(100% - 120px);
      border: 0;
      border-bottom: 1px solid $grey;
      border-radius: 0;
      padding-left: 20px;
      font-size: $font-size;
      line-height: 40px;
      padding-bottom: 2px;
    }
  }
}

.totale {
  margin-top: -1.55rem;
  pointer-events: none;
  min-height: 22px;

  @include media-breakpoint-down(xs) {
    margin-top: 1rem;
  }

  svg {
    margin-left: 0.2rem;
    font-size: $font-size + 0.1;
    color: $blue;
  }
}

.filtri {
  * {
    font-size: $font-size - 0.1;
  }

  svg {
    color: $blue;
  }

  .btn {
    color: $blue;
    background: transparent;
    border: 2px solid $blue;
    font-size: $font-size - 0.2;
    margin-right: 7px;
    padding: 2px 5px;

    svg {
      font-size: $font-size - 0.2;
    }

    &:hover,
    &.active {
      color: $white;
      background: $blue;
      border-color: $blue;

      svg {
        color: $white;
      }
    }
  }

  select {
    max-width: 200px;
    height: 25px;
    border: 0;
    box-shadow: 0px 0px 2px;
    display: inline;
    margin: 0;
    margin-left: 0.5rem;
    margin-right: 0.2rem;
    padding: 2px 5px;
  }

  #filtri-data {
    display: inline;
    margin-left: 8px;

    .custom-interval {
      text-align: right;
      margin: 10px 10px 10px 0;
      padding: 10px;
      background: $blue;

      label {
        color: $white;
        margin: 0 10px 0 20px;
        font-weight: 500;
      }
    }
  }
}

.no-risultati {
  width: 100%;
  text-align: center;
  padding: 40px 0;
}

.notizia {
  padding: 20px 30px;
  position: relative;

  &:hover {
    box-shadow: 0px 0px 8px $grey;
    background: #ffefe3;
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    .sorgente {
      margin-left: auto;

      .badge {
        color: $white;
        text-transform: uppercase;
      }
    }

    .arg {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: $blue;
      position: relative;

      .square {
        display: block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        background: $green;
        text-align: center;
        color: $white;
        text-transform: uppercase;
        position: absolute;
        top: 5px;
        left: 5px;
      }
    }

    .parent {
      display: inline-block;
      text-transform: uppercase;
      color: $blue;
      font-size: $font-size;
      font-weight: 500;
      margin-left: 20px;
      vertical-align: super;
    }

    .badge {
      font-size: $font-size - 0.1;
      font-weight: 500;
      margin-right: 10px;
    }

    .categorie {
      display: inline-block;
      vertical-align: super;
      margin: 0 30px;

      .badge {
        background: rgba($grey, 0.8);
        margin-right: 10px;
      }
    }
  }

  .testi {
    h2 {
      font-size: $font-size + 0.5;

      a {
        color: $text-color;
        font-size: inherit;

        &:hover {
          color: $orange;
          text-decoration: underline;
        }
      }
    }

    h3 {
      color: $blue;
      font-size: $font-size + 0.2;
    }

    h5.data,
    h5.data time {
      font-size: $font-size;
      margin-top: 20px;
    }

    p {
      text-align: justify;
      hyphens: auto;
    }

    p::after,
    p span::before {
      content: '...';
      letter-spacing: 0.1em;
      margin: 0 0.25em;
    }

    p span em {
      font-weight: 700;
      padding: 0 0.3em 0 0.2em;
      background-color: rgba($green, 0.3);
    }
  }
}

.infinite-scroll-component {
  overflow: inherit !important;
}

div.scrollToTopBtn {
  position: sticky;
  top: 0;
  z-index: 98;

  div {
    position: absolute;
    right: 0.5rem;
    margin-top: calc(100vh - 3.5rem);
    box-shadow: 1px 1px 6px rgba(black, 0.8);
    border-radius: 100%;
    background-color: white;
    transform: translate(50%, -50%);
    cursor: pointer;
    z-index: 9999;

    svg {
      margin: -2px;
      font-size: 3rem;
      color: $orange;
    }

    &:hover svg {
      font-size: 3.5rem;
    }
  }
}

.tab-risultati {
  .nav {
    .nav-item {
      flex: 1 0 0;

      .nav-link {
        border-width: 0 0 3px 0;
        border-color: transparent;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
      }

      &.all .nav-link {
        &:hover,
        &.active {
          // color: $blue;
          border-color: $blue;
        }
      }

      &.fiscale .nav-link {
        &:hover,
        &.active {
          // color: $green;
          border-color: $green;
        }
      }

      &.giuridico .nav-link {
        &:hover,
        &.active {
          // color: $red;
          border-color: $red;
        }
      }
    }
  }
}

.results-col-container {
  min-height: 40vh;
}
