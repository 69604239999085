.Breadcrumb {
  a {
    padding: 0;
    color: $blue;

    &:hover {
      text-decoration: underline;
    }

    &.disabled:hover {
      text-decoration: none;
    }
  }

  span {
    display: inline-block;

    &.divider {
      margin: 0 15px;
    }
  }
}
