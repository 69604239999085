$white: #ffffff;
$black: #000000;
$blue: #438abe;
$print-font-size: 16pt;

@page {
  size: 210mm 297mm;
  margin: 25mm;
}

body.print {
  * {
    font-family: Garamond, 'Times New Roman', Times, serif !important;
    font-size: $print-font-size;
    color: $black;
  }

  #news {
    .logo-fiscal-box {
      display: block !important;

      img {
        width: 300px;
      }

      margin-bottom: 10mm;
    }

    .data {
      position: absolute;
      top: 0;
      right: 0;

      time {
        display: inline-block;
        font-size: $print-font-size - 2 !important;
        font-weight: 700;
        text-transform: capitalize;
        border-bottom: 1px solid $blue;
        color: $blue;
        padding: 8px 0 8px 10px;
      }
    }

    h1 {
      margin-bottom: 30px;
      color: $blue;
      font-size: $print-font-size + 8 !important;
    }

    h2 {
      font-size: $print-font-size + 4 !important;
      font-weight: 700;
      color: $black;
      margin-bottom: 20px;
    }

    h3  {
      font-size: $print-font-size + 2 !important;
      color: $black;
      font-style: normal;
    }

    .firma {
      font-weight: 700;

      span {
        font-weight: normal;
        font-style: italic;
      }
    }

    .p {
      text-align: justify;
      * {
        font-size: $print-font-size + 1 !important;
      }
    }

    .fonte {
      margin-top: 50px;
    }
  }

  .right-column {
    display: none !important;
  }
}
