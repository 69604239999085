.Notizia {
  .white-box {
    padding: 20px 30px;
    height: 100%;
  }

  @include media-breakpoint-down(md) {
    .header {
      > div:nth-child(1) {
        order: 2;
      }
      > div:nth-child(2) {
        order: 1;
      }
    }
  }

  h1 {
    font-size: $font-size + 1.2;

    &.margin-bottom {
      margin-bottom: 50px;
    }

    @include media-breakpoint-down(md) {
      font-size: $font-size + 1.5;
    }

    @include media-breakpoint-down(sm) {
      font-size: $font-size + 0.9;
    }
  }

  h2 {
    font-size: $font-size + 0.6;
    color: $blue;
  }

  h3 {
    font-style: italic;
    font-size: $font-size + 0.6;
    margin-top: 15px;
  }

  h5.data {
    margin: 10px 0 0 -55px;

    time {
      display: inline-block;
      font-size: $font-size + 0.1;
      text-transform: capitalize;
      background: $blue;
      color: $white;
      padding: 8px 40px;
      padding-left: 55px;
    }
  }

  p.firma {
    margin: 10px 0;
    span {
      color: $blue;
      font-weight: 500;
    }
  }

  .p {
    margin-top: 20px;
    text-align: justify;

    * {
      font-family: $font-paragraphs !important;
      font-size: $font-size + 0.1 !important;
    }

    p.MsoNormal {
      margin: 0;
    }

    br {
      display: none;

      + br {
        display: inline;

        + br {
          display: none;
        }
      }
    }

    ul {
      margin: 10px 0;
    }
  }

  p.fonte {
    display: block;
    margin-top: 25px;
    font-style: italic;
  }

  $sticky-top-right-col: 57px;
  $right-col-header-height: 47px;
  .azioni,
  .indice-parti {
    position: sticky;
    top: $sticky-top-right-col;
    padding: 0;
    height: auto;

    @include media-breakpoint-down(md) {
      margin-top: 40px;
    }

    .header {
      height: $right-col-header-height;
      background: $blue;
      padding: 10px 0;
      color: $white;
      // text-transform: uppercase;
      text-align: center;
      font-family: $font-headings;
      font-size: $font-size + 0.2;
    }

    .menu-parti,
    .allegati-container {
      max-height: calc(100vh - #{$sticky-top-right-col} - #{$right-col-header-height} - 20px);
      overflow: auto;
    }

    .allegati-container {
      padding: 20px;
      border-bottom: 1px solid $grey;

      h5 {
        font-size: $font-size + 0.2;
      }

      svg {
        font-size: $font-size + 0.2;
        color: $text-color;
        vertical-align: sub;
      }
      .allegati {
        margin: 20px 0;
      }

      .allegato {
        margin: 20px 0;
      }
      .nome {
        padding: 5px 10px;
        background: $light-grey;
        color: $text-color;
      }

      .noAll {
        font-style: italic;
      }

      a {
        display: block;
        margin: 10px 0;
        display: table;

        svg {
          font-size: $font-size + 0.6;
          color: $orange;
          display: table-cell;
          vertical-align: top;
        }

        .nome {
          background: transparent;
          color: $orange;
          display: table-cell;
          vertical-align: top;
        }

        &:hover {
          text-decoration: none;
          svg {
            color: $blue;
          }

          .nome {
            text-decoration: none;
            color: $blue;
          }
        }
      }
    }

    .pulsanti {
      padding: 20px;
    }
  }

  .indice-parti {
    @include media-breakpoint-down(md) {
      position: fixed;
      top: unset;
      left: 10vw;
      right: 10vw;
      bottom: 0;
      z-index: 1000;
      transform: translateY(200px);
      transition: transform 0.2s ease-in-out;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: initial;
        padding: 10px 20px;

        svg {
          font-size: 1.2rem;
          margin-top: 4px;
        }
      }

      .menu-parti {
        height: 200px;
        overflow-y: auto;
      }

      &.open {
        transform: translateY(0);
      }
    }
  }

  #print {
    border-radius: 0;
    background-color: $actions;
    border: 0;
    margin-top: 10px;
    background: transparent;
    color: $blue;
    svg {
      font-size: 1.1rem;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      text-decoration: underline;

      svg {
        transform: scale(1.15);
      }
    }
  }
}
