.Loading {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  // transform: translate(-50%, -50%);
  opacity: 0.8;

  img {
    animation: rotate 1.8s linear infinite;
    width: 130px;
  }

  .infinite-scroll-component &,
  .white-box.content & {
    width: 80px;
    height: 80px;
    margin: 10rem auto;
    position: static;

    img {
      width: 80px;
    }
  }
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
