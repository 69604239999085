#wrapper {
  background: $light-grey;

  &.menu-mobile-open::after {
    content: '';
    background: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
  }
}

#page-content-wrapper {
  background: $light-grey;

  @include media-breakpoint-up(md) {
    min-width: 0;
    width: 100%;
  }
}

main {
  position: relative;
  min-height: 100vh;
  padding: 40px 40px 80px;

  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

.d-lg-none {
  @include media-breakpoint-down(lg) {
    display: initial !important;
  }
}