.Sezione {
  .nuova-ricerca {
    background: $orange;
    border-radius: 0;
    border: 0;
    padding: 8px 20px;
    text-transform: uppercase;

    span {
      font-size: $font-size - 0.1;
    }

    &:hover {
      background: $green;
    }
  }
}
