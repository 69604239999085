// -----
// Colors

$white: #ffffff;
$black: #000000;
$light-grey: #f0f0f0;
$grey: #98abba;
$blue: #438abe;
$red: #e14141;
$orange: #fe811c;
$green: #32d171;
$text-color: #333333;
$abbonamenti: #e86f4c;
$actions: #c8505b;

// -----
// Fonts

$font-headings: 'mr-eaves-xl-sans', sans-serif;
$font-paragraphs: 'proxima-nova', sans-serif;
$font-size: 0.95rem;

// -----
// Containers

//sidebar
$sidebar-width: 20vw;
$sidebar-max-width: 240px;
$sidebar-md-width: 80vw;
$sidebar-md-max-width: 80vw;
$sidebar-sm-width: 100vw;
$sidebar-sm-max-width: 100vw;

//submenu
$submenu-width: 25vw;
$submenu-max-width: 300px;
$submenu-md-width: 80vw;
$submenu-md-max-width: 80vw;
$submenu-sm-width: 100vw;
$submenu-sm-max-width: 100vw;
