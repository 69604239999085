.alert.error {
  margin: 30vh auto;
  text-align: center;

  .white-box & {
    margin: 0 auto;
  }

  details {
    display: inline-block;
    &:focus,
    &:focus-within {
      outline: 0;
      box-shadow: none;
    }
  }
}
