.TreeView {
  ul {
    list-style: none;
    padding: 20px;

    li {
      border-bottom: 2px solid $white;
      cursor: pointer;

      .list-voice-container {
        display: flex;
        align-items: center;
        padding: 8px 10px;
        flex: 1 0 0;
        margin-left: 5px;

        &:hover {
          background: mix($white, $blue, 65%);
        }
      }

      &.selected {
        > div > .list-voice-container {
          background: $blue;
          color: $white;
        }
      }

      .arrow {
        font-size: $font-size + 0.2;
        padding: 5px 4px;
        transition: font-size 0.2s ease-out;

        &:hover {
          svg {
            color: mix($black, $blue, 40%) !important;
          }
        }
      }
    }

    ul {
      display: none;
      padding: 2px 0 0 25px;

      &.open {
        display: block;
      }
    }
  }
}
