.Home {
  .logo-faded {
    opacity: 0.5;
    img {
      width: 160px;
    }
  }

  .Banner {
    img {
      max-width: 100%;
    }

    @include media-breakpoint-down(md) {
      float: left;
      margin-right: 15px;
    }

    @include media-breakpoint-down(xs) {
      float: none;
      margin-right: 0;

      img {
        width: 100%;
      }
    }
  }
}
