#sidebar-wrapper {
  flex: 0 0 $sidebar-width;
  max-width: $sidebar-max-width;
  min-height: 100vh;
  background: $white;

  @include media-breakpoint-down(md) {
    transition: all 0.3s ease-out;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 100;

    &.closed {
      flex: 0 0 0;
      max-width: 0;
      padding: 0;

      .Sidebar {
        display: none;
      }
    }

    &.open {
      flex: 0 0 $sidebar-md-width;
      max-width: $sidebar-md-max-width;
    }
  }

  @include media-breakpoint-down(sm) {
    right: 0;
    &.open {
      flex: 0 0 $sidebar-sm-width;
      max-width: $sidebar-sm-max-width;
    }
  }

  .toggler {
    position: absolute;
    top: 1vh;
    right: 0;
    color: $red;
    font-size: 1.5rem;
    padding: 20px;
    z-index: 2;

    &.close-btn {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &:not(.closed) .sidebar-container {
    width: $sidebar-max-width;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding-left: 15px;
  }

  .sidebar-container.mobile {
    position: static;
    max-width: 100vw;
    width: 100%;
    margin-left: -15px;
    display: flex;

    &.navbar .nav li {
      border-bottom: 1px solid $light-grey;

      a {
        font-size: $font-size + 0.1;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .sidebar-container {
      width: $sidebar-max-width;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
    }
  }
}

.Sidebar {
  position: fixed;
  width: $sidebar-max-width;
  padding: 0.5rem 0;

  @include media-breakpoint-down(md) {
    width: calc(#{$sidebar-md-max-width} - 30px);
  }

  @include media-breakpoint-down(sm) {
    width: calc(#{$sidebar-sm-max-width} - 45px);
  }

  &.navbar {
    display: block;

    .navbar-brand {
      width: 100%;
      padding-top: 20px;
      margin-right: 0;
      text-align: center;

      img {
        width: calc(#{$sidebar-max-width} - 70px);
        margin-left: -19px;

        @include media-breakpoint-down(lg) {
          width: calc(#{$sidebar-width} - 60px);
        }

        @include media-breakpoint-down(sm) {
          width: 200px;
        }
      }
    }

    .nav {
      li {
        flex: 0 0 calc(100% + 62px);
        max-width: $sidebar-max-width;
        position: relative;

        @include media-breakpoint-down(lg) {
          max-width: unset;
        }

        a {
          color: $text-color;
          padding: 11px 30px;

          &:hover,
          &.active {
            background: $blue;
            color: $white;
          }
        }

        .jSquare {
          display: inline-block;
          width: 15px;
          height: 15px;
          margin-right: 0.3rem;
          background: #e14141;
          font-size: $font-size - 0.165;
          font-weight: bold;
          color: $white;
          text-align: center;
          line-height: 15px;
          vertical-align: -0.145rem;
        }

        .Submenu-toggle {
          height: 100%;
          display: inline-block;
          color: $white;
          background: $blue;
          padding: 12px 20px;
          border-left: 1px solid $white;
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;

          &:hover {
            background: darken($blue, 15%);
          }

          @include media-breakpoint-down(lg) {
            right: 62px;
          }
        }
      }
    }
  }
}

#fiscal-focus {
  height: 60px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 3px;
  background: $white;

  p {
    text-align: center;
    font-size: $font-size - 0.1;
    font-style: italic;
    margin: 0 0 4px;
  }

  h4 {
    text-align: center;
    font-size: $font-size + 0.2;
  }

  img {
    width: 80%;
    transition: transform 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }

  &.mobile {
    display: block;

    img {
      width: 140px;
    }
  }
}

@include media-breakpoint-down(md) {
  #sidebar-wrapper.closed #fiscal-focus {
    display: none;
  }
}
