.Ricerca {
  background: $white;
  padding: 17px 23px 25px;
  margin-top: 1.5rem;

  .modal & {
    margin-top: 0;
    padding: 15px 23px;
  }

  .titolo {
    span {
      display: inline-block;
      vertical-align: middle;
      padding: 8px 14px 8px 0;
      color: $blue;
      line-height: 19px;

      svg:first-of-type {
        margin-right: 4px;
      }
    }

    h2 {
      display: inline-block;
      vertical-align: middle;
      font-size: $font-size + 0.8;
      margin-bottom: 0;
    }
  }

  .info {
    p {
      font-style: italic;
    }
  }

  label {
    margin: 8px 0;
    font-weight: bolder;
    text-transform: uppercase;
    font-size: $font-size - 0.05;

    + div {
      label {
        font-weight: normal;
        margin: 0 20px;
        text-transform: none;
      }
    }
  }

  div.input-testo {
    display: flex;
    div {
      flex: 0 0 15%;
      max-width: 15%;
      background: $white;
      text-align: center;
      position: relative;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    input {
      flex: 0 0 85%;
      max-width: 85%;
    }
  }

  input[type='text'] {
    width: 100%;
    max-width: 1200px;
    margin: 0;
    border: 0;
    box-shadow: 0px 0px 3px $grey;
    border-radius: 0;
    font-size: $font-size;
    font-weight: 300;

    &:focus {
      box-shadow: 0px 0px 5px $blue !important;
    }
  }

  #argomenti-editoria,
  #argomenti-banca,
  #filtri-data,
  #filtro-anno {
    line-height: 2.7rem;

    .arg-label {
      line-height: 1;
    }

    > div + div > .arg-label {
      margin-top: 0.75rem;
    }

    button {
      border: 0;
      border-radius: 0;
      background: $grey;
      color: $white;
      text-transform: uppercase;
      margin-right: 10px;
      font-size: $font-size - 0.2;

      &:last-child {
        margin-right: 0;
      }

      &:not(.disabled):hover {
        background: $blue;
      }

      &.active {
        background: $green;
      }
    }
    .custom-interval {
      input,
      label,
      .input-group-text {
        font-size: $font-size - 0.1;
      }

      > div {
        display: table-row;
        padding-bottom: 10px;
      }

      label {
        padding: 0 15px;
      }

      label {
        display: table-cell;
        padding-bottom: 10px;
      }

      .react-datepicker__input-container {
        display: block;
      }

      input {
        padding-left: 8px;
      }
    }

    .invalid-feedback {
      line-height: 1.3;
      margin-top: 10px;
      font-weight: bold;
    }

    .form-text {
      line-height: 1.3;
    }
  }

  button[disabled],
  input[disabled] {
    cursor: not-allowed;
  }

  .SearchCategoria {
    .selected-categories {
      .badge {
        font-size: $font-size - 0.2;
        background: $green;
        text-transform: uppercase;
        margin-bottom: 15px;
        font-weight: 400;
        cursor: pointer;

        &:hover {
          background: $orange;
        }
      }
    }

    .react-autosuggest__suggestions-container--open {
      background: $white;
      box-shadow: inset 0px 0px 4px #f0f0f0;
      max-width: 1200px;
      max-height: 300px;
      overflow-y: auto;
      padding: 20px 0px;

      ul {
        list-style: none;
        padding: 0;

        li {
          padding: 15px 25px;
          border-bottom: 1px solid $light-grey;

          &.react-autosuggest__suggestion--highlighted {
            background: $light-grey;
          }

          .suggestion {
            display: table;

            .icon {
              display: table-cell;
              padding-right: 20px;

              span {
                display: inline-block;
                width: 30px;
                height: 30px;
                background: $green;
                color: $white;
                font-weight: 500;
                text-align: center;
                line-height: 30px;
              }
            }

            .name {
              display: table-cell;
            }
          }
        }
      }
    }
  }

  button.submit {
    background: $blue;
    border: 0;
    border-radius: 0;
    font-size: $font-size + 0.1;
    color: $white;
    text-transform: uppercase;
    width: 300px;

    &:hover {
      background: $orange;
    }

    svg {
      font-size: $font-size - 0.1;
    }
  }

  &.sidebar {
    width: $submenu-max-width;
    margin-top: 0;
    background: $blue;
    position: fixed;
    top: 0;
    left: $sidebar-max-width;
    bottom: 0;
    z-index: 4;

    @include media-breakpoint-down(lg) {
      left: $sidebar-width;
    }

    .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
      right: -13px !important;
    }

    .titolo {
      span,
      h2 {
        font-size: $font-size + 0.3;
        color: $white;
        -webkit-font-smoothing: antialiased;
      }

      span {
        font-size: $font-size + 0.1;
      }
    }

    .info {
      margin-top: 0 !important;
    }

    label {
      margin: 5px 0 10px;
    }

    .info p,
    label {
      -webkit-font-smoothing: antialiased;
      color: $white;
    }

    #argomenti-editoria,
    #argomenti-banca,
    #filtri-data,
    #filtro-anno {
      .arg-label {
        color: $white;
        border-bottom: 1px solid $white;
        line-height: 1.2;
        padding-bottom: 8px;
        margin-bottom: 10px;
        font-size: $font-size;
      }

      > div + div {
        margin-top: 20px;
      }

      button {
        display: block;
        margin-bottom: 10px;
        background: $white;
        color: $blue;
        font-weight: 400;

        &:not(.disabled):hover,
        &.active {
          background: $green;
          font-weight: 400;
          color: $white;
        }
      }

      .custom-interval {
        padding-left: 0;
        background: rgba($blue, 0.2);

        label {
          padding-left: 0;
          padding-right: 15px;
        }
      }

      .invalid-feedback {
        padding: 3px;
        background: mix($white, $red, 80%);
      }
    }
  }

  &.mobile {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 70vw;
    z-index: 4;
    text-align: left;

    div.input-testo {
      display: flex;
      div {
        flex: 0 0 8%;
        max-width: 8%;
        background: $white;
        text-align: center;
        position: relative;

        @include media-breakpoint-down(sm) {
          flex: 0 0 12%;
          max-width: 12%;
        }

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      input[type='text'] {
        flex: 0 0 52%;
        max-width: 52%;

        @include media-breakpoint-down(sm) {
          flex: 0 0 88%;
          max-width: 88%;
        }
      }
    }
    input[type='text'] {
      flex: 0 0 60%;
      max-width: 60%;

      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}
#mobile-ricerca-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: rgba($black, 0.5);
}

.closeModal {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 2;
  padding: 20px;
  cursor: pointer;

  svg {
    color: $red;
    font-size: $font-size + 0.4;
  }
}

.react-autosuggest__suggestion-match {
  font-weight: bolder;
}

.btn-ricerca {
  border-radius: 0;
  border: 0;
  padding: 8px 20px;
  text-transform: uppercase;

  span {
    font-size: $font-size - 0.1;
  }

  &:hover {
    background: $green;
  }

  &.nuova {
    background: $orange;

    @include media-breakpoint-down(md) {
      float: right;
    }
  }
  &.modifica {
    background: $blue;

    @include media-breakpoint-down(md) {
      float: left;
    }
  }
}

.react-datepicker-popper {
  left: -30px !important;
  border: 0;

  .react-datepicker__day--outside-month {
    color: darken($light-grey, 10%);
  }
  .react-datepicker {
    button.react-datepicker__navigation {
      width: 20px;
      height: 20px;
      border: none;
      margin: 0 !important;
      font-size: 0 !important;

      &:hover {
        background: $blue !important;
        color: $white;
      }
    }
    .react-datepicker__navigation--previous::before {
      content: '<';
      display: block;
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 1rem;
    }
    .react-datepicker__navigation--next::before {
      content: '>';
      display: block;
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 1rem;
    }
  }

  // .react-datepicker__header {
  //   background-color: $blue;
  //   border-color: $blue;
  // }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  background-color: $blue;
}

.filtri-ricerca {
  #argomenti-editoria,
  #argomenti-banca,
  #filtri-data,
  #filtro-anno {
    line-height: 2.7rem;

    .arg-label {
      line-height: 1;
    }

    button {
      border: 0;
      border-radius: 0;
      background: $grey;
      color: $white;
      text-transform: uppercase;
      margin-right: 10px;
      font-size: $font-size - 0.2;

      &:last-child {
        margin-right: 0;
      }

      &:not(.disabled):hover {
        background: $blue;
      }

      &.active {
        background: $green;
      }
    }

    .custom-interval {
      input,
      label,
      .input-group-text {
        font-size: $font-size - 0.1;
      }

      label {
        padding: 0 15px;
      }

      label {
        display: table-cell;
        padding-bottom: 10px;
      }

      .react-datepicker__input-container {
        display: block;
      }

      input {
        padding-left: 8px;
      }
    }

    .invalid-feedback {
      line-height: 1.3;
      margin-top: 10px;
      font-weight: bold;
    }

    .form-text {
      line-height: 1.3;
    }
  }

  button[disabled],
  input[disabled] {
    cursor: not-allowed;
  }
}
