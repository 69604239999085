.Abbonati {
  width: 100vw;
  background: $light-grey;
  height: 100vh;

  .login-box {
    background: $white;
    text-align: center;
    padding: 40px 5vw;
    box-shadow: 0px 1px 3px $grey;

    p.msg {
      font-size: $font-size + 0.3;
      font-weight: 300;
    }

    .form-group {
      text-align: left;
    }

    .btn {
      background: $blue;
      border-color: $blue;
      text-transform: uppercase;
      border-radius: 0;
      padding-left: 4rem;
      padding-right: 4rem;
      font-size: $font-size + 0.4;
      margin-top: 30px;

      &:hover {
        background: $orange;
        border-color: $orange;
      }
    }
  }
}
