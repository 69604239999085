#submenu-wrapper {
  flex: 0 0 $submenu-width;
  max-width: $submenu-max-width;
  min-height: 100vh;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  background: $blue;

  &.closed {
    flex: 0 0 0;
    max-width: 0;
    z-index: -1;
    padding: 0;
  }

  &.open {
    position: static;
    flex: 0 0 $submenu-width;
    max-width: $submenu-max-width;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }

  @include media-breakpoint-up(md) {
    margin-left: 0;
  }
}

.Submenu {
  position: fixed;
  top: 6vh;
  max-width: calc(#{$submenu-max-width} - 30px);

  @include media-breakpoint-down(lg) {
    max-width: calc(#{$submenu-width} - 30px);
  }

  .Submenu-toggle {
    background: transparent;
    border: 0;
    position: absolute;
    top: -4vh;
    right: 0;

    @include media-breakpoint-down(md) {
      top: 15px;
    }

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      background: $white !important;
      color: $blue !important;
    }
  }

  li {
    display: block;
    width: 100%;
  }

  a {
    color: $white;
    font-size: $font-size;
    font-weight: 300;
    padding: 0.4rem 0.3rem;
    position: relative;

    @include media-breakpoint-down(md) {
      border-bottom: 1px solid $white;
      margin-bottom: 4px;
    }

    &.active {
      padding-left: 15px;
      font-weight: 500;
      background: darken($blue, 10%);
    }

    &:hover {
      background: $white;
      color: $blue;
    }
  }

  li.no-link {
    color: $white;
    margin: 10px 0 10px;

    &:before {
      content: '';
      width: 232px;
      height: 1px;
      background: #ffffff;
      display: block;
      margin-bottom: 43px;
    }

    &:first-of-type:before {
      display: none;
    }

    span {
      font-size: $font-size + 0.1;
      font-weight: 400;
    }
  }

  #sidebar-wrapper & {
    @include media-breakpoint-down(md) {
      transition: all 0.3s ease-out;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 101;
      min-height: 100vh;
      background: #438abe;
      padding: 40px 30px 30px;

      &.closed {
        width: 0;
        max-width: 0;
        padding: 0;
        visibility: hidden;
      }

      &.open {
        position: fixed;
        width: $submenu-md-width;
        max-width: $submenu-md-max-width;
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 80px 30px 30px;
      right: 0;

      &.open {
        width: $submenu-sm-width;
        max-width: $submenu-sm-max-width;
      }
      .Submenu-toggle {
        top: 3vh;
        right: 15px;
      }
    }
  }
}
