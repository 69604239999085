body,
body * {
  font-family: $font-paragraphs;
  font-size: $font-size;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-headings;
}

h1 {
  font-size: $font-size + 2.4;
  font-weight: 800;

  @include media-breakpoint-down(xs) {
    font-size: $font-size + 1.2;
  }
}

h2 {
  font-size: $font-size + 1.5;
  font-weight: 600;
}

h3 {
  font-size: $font-size + 0.8;
  font-weight: 600;
}

a,
button,
.btn {
  &:active,
  &:focus,
  &:focus-within {
    outline: 0;
    box-shadow: none !important;
  }
}
